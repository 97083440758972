import { z } from 'zod';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { SpeciesObject } from '../species-object';

const TriggerSchema = z.object({
  name: z.string(),
  conditions: z
    .array(
      z.object({
        order: z.number().optional(),
        source: z.string().optional(),
        data_type: z.string().optional(),
        query_type: z.string().optional(),
        coverage_type: z.string().optional(),
        coverage_count: z.string().optional(),
        attribute_value: z.string().optional(),
        attribute_name: z.string().optional(),
        query_attributes: z
          .array(
            z.object({
              key: z.string(),
              value: z.string(),
              operator: z.string(),
            }),
          )
          .optional(),
      }),
    )
    .optional(),
  recommendation: z.string().optional(),
});

const TriggerGroup = z.object({
  name: z.string(),
  order: z.number(),
  triggers: z.array(TriggerSchema).optional(),
});

export const SpeciesShowDTO = SpeciesObject.extend({
  parent: SpeciesObject.nullable(),
  seasonality: z
    .array(
      z.object({
        type: z.string(),
        notes: z.string().nullable().optional(),
        end_day: z.number(),
        end_month: z.number(),
        start_day: z.number(),
        start_month: z.number(),
      }),
    )
    .nullable(),
  admin: z
    .object({
      notes: z.string().nullable(),
      map_notes: z.string().nullable(),
      flag_for_followup: z.boolean().nullable(),
    })
    .deepPartial(),
  content: z
    .object({
      habitat_requirements: z.string().optional().nullable(),
      commentary_regulatory: z.string().optional().nullable(),
      current_range: z.string().optional().nullable(),
      elevation_range_min: z.number().optional().nullable(),
      elevation_range_max: z.number().optional().nullable(),
      life_history: z.string().optional().nullable(),
      historic_range: z.string().optional().nullable(),
    })
    .deepPartial()
    .nullable(),
  habitat_characteristics: z.array(z.string()),
  triggers: z.array(TriggerGroup),
  // bbox: GeometrySchema.nullable(),
  // ecos: z
  //   .object({
  //     profile: z.object({
  //       DPS: z.string(),
  //       TSN: z.string(),
  //       FAMILY: z.string(),
  //       SPCODE: z.string(),
  //       STATUS: z.string(),
  //       COMNAME: z.string(),
  //       COUNTRY: z.string(),
  //       INVNAME: z.string(),
  //       SCINAME: z.string(),
  //       VIPCODE: z.string(),
  //       POP_DESC: z.string(),
  //       ENTITY_ID: z.string(),
  //       POP_ABBREV: z.string(),
  //       LEAD_AGENCY: z.string(),
  //       LEAD_REGION: z.string(),
  //       STATUS_TEXT: z.string(),
  //       REFUGE_OCCURRENCE: z.string(),
  //     }),
  //     counties: z.array(z.object({ state: z.string(), county: z.string() })),
  //     critical_habitat: z.object({
  //       tsn: z.number(),
  //       Lead: z.object({
  //         url: z.string(),
  //         name: z.string(),
  //       }),
  //       entityId: z.number(),
  //       'Common Name': z.string(),
  //       'Where Listed': z.string(),
  //       'Species Group': z.string(),
  //       'Scientific Name': z.object({
  //         url: z.string(),
  //         name: z.string(),
  //       }),
  //       'FWS Listing Status': z.string(),
  //       'Critical Habitat Type': z.string(),
  //       'Critical Habitat Shapefile': z.array(
  //         z.object({
  //           url: z.string(),
  //           name: z.string(),
  //           wkt_bb: z.string(),
  //         })
  //       ),
  //       'Federal Register Publication': z.object({
  //         url: z.string(),
  //         name: z.string(),
  //       }),
  //     }),
  //     species_occurrence: z.object({
  //       tsn: z.number(),
  //       Group: z.string(),
  //       Status: z.string(),
  //       invname: z.object({ url: z.string(), name: z.string() }),
  //       wktbbox: z.string(),
  //       entityId: z.number(),
  //       'Pop Abbrev': z.string(),
  //       'Scientific Name': z.string(),
  //     }),
  //   })
  //   .deepPartial(),
});

export type SpeciesShowDTO = z.infer<typeof SpeciesShowDTO>;

export const SpeciesShowPreviewDTO = z.object({
  _id: z.string().uuid(),
  preview: GeometrySchema.nullable(),
});

export type SpeciesShowPreviewDTO = z.infer<typeof SpeciesShowPreviewDTO>;
