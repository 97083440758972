import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  forkJoin,
  from,
  Subject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Passwordless from 'supertokens-web-js/recipe/passwordless';
import Session from 'supertokens-web-js/recipe/session';
import { environment } from '../environments/environment';
import { InProgressRequestsMenuService } from './layouts/portal-layout/components/in-progress-requests-menu/services/in-progress-requests-menu.service';
import { NotificationService } from './modules/notification/services/notification.service';
import { UnrecoverableStateDialogService } from './modules/unrecoverable-state-dialog/services/unrecoverable-state-dialog.service';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './services/auth.service';
import { CheckForUpdateService } from './services/check-for-update.service';
import { ConfigCatService } from './services/config-cat.service';
import { ReportService } from './services/report.service';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty';

SuperTokens.init({
  appInfo: {
    appName: 'Transect',
    apiDomain: environment.apiUrl,
  },
  recipeList: [
    EmailPassword.init(),
    Passwordless.init(),
    ThirdParty.init(),
    Session.init({
      tokenTransferMethod: window.self !== window.top ? 'header' : 'cookie',
      sessionTokenBackendDomain:
        environment.transectEnvironment === 'local'
          ? 'localhost'
          : '.transect.com',
      isInIframe: window.self !== window.top,
    }),
  ],
});

@Component({
  selector: 'ts-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private currentSpecialClass: string | null = null;

  private isLoadingRoute$ = new BehaviorSubject(false);
  private isInitializingUser$ = new BehaviorSubject(true);

  private loadUserIfLoggedIn$ = forkJoin({
    user: this.auth.userOrNull$.pipe(take(1)),
    isTokenSet: from(this.auth.isTokenSet()).pipe(take(1)),
  }).pipe(
    switchMap(({ isTokenSet, user }) => {
      if (isTokenSet && !user) {
        return this.auth.fetchCurrentUser();
      }

      return EMPTY;
    }),
    finalize(() => this.isInitializingUser$.next(false)),
    catchError(() => {
      return EMPTY;
    }),
    map(() => {
      return;
    }),
  );

  view = {
    state: {
      isLoadingRoute$: this.isLoadingRoute$
        .asObservable()
        .pipe(distinctUntilChanged()),
      isInitializingUser$: this.isInitializingUser$
        .asObservable()
        .pipe(distinctUntilChanged()),
    },
    actions: [
      this.loadUserIfLoggedIn$,
      this.checkForUpdatesService.listenToVersionEvents$,
    ],
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private auth: AuthService,
    private gtmService: GoogleTagManagerService,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsService,
    private reportService: ReportService,
    private checkForUpdatesService: CheckForUpdateService,
    private unrecoverableStateDialogService: UnrecoverableStateDialogService,
    private inProgressRequestMenuService: InProgressRequestsMenuService,
    private configCatService: ConfigCatService,
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
      if (event instanceof NavigationStart && window.analytics) {
        window.analytics.page();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.auth
      .checkForAccessTokenRefresh()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.configCatService.removeAriaOwnAttribute$
      .pipe(
        filter(Boolean),
        tap(() => {
          this.setupMutationObserver();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.checkForUpdatesService
      .pollUpdateAvailability()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.checkForUpdatesService.unrecoverableState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reason) => {
        this.unrecoverableStateDialogService.open(reason);
      });

    this.auth.userOrNull$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.auth.setUserPreferences(user?.preferences ?? null);
    });

    this.auth.onLogout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.reportService.resetAllReportCache();
    });

    try {
      void this.gtmService.addGtmToDom();
      this.auth.userObserver$
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          if (user) {
            void this.gtmService.pushTag({
              event: 'data_layer_ready',
              userId: user._id,
              userEmail: user.email,
              firstName: user.firstname,
              lastName: user.lastname,
              transectPlanName: user.transect_plan_name,
              industry: user.industry?.name,
              industryKey: user.industry?.key,
              reportOwnedCount: user.report_owned_count,
            });
          }
        });
    } catch (e) {
      console.warn(e);
      console.warn('Failed to start google tag manager.');
    }

    this.isLoadingRoute$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      if (loading) {
        this.renderer.addClass(document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(document.body, 'overflow-hidden');
      }
    });

    this.auth.userObserver$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.analyticsService.identify(user);
      } else {
        this.analyticsService.deidentify();
      }
    });

    combineLatest([this.router.events, this.route.data])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([event, data]) => {
        if (event instanceof GuardsCheckStart) {
          this.isLoadingRoute$.next(true);
        }

        if (
          event instanceof GuardsCheckEnd ||
          event instanceof NavigationCancel
        ) {
          this.isLoadingRoute$.next(false);
        }

        if (event instanceof NavigationEnd) {
          const specialClass = data?.specialClass as string;
          this.updateBodyClasses(specialClass);
        }
      });

    this.notificationService
      .initNotificationListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.auth.userObserver$
      .pipe(
        switchMap(() =>
          this.inProgressRequestMenuService.fetchAndSetInProgressRequests(),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.inProgressRequestMenuService
      .initInProgressRequestsListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((inProgressRequest) => {
        this.inProgressRequestMenuService.handleInProgressRequestReceived(
          inProgressRequest,
        );
      });
  }

  updateBodyClasses(specialClass: string): void {
    if (specialClass) {
      this.currentSpecialClass = specialClass;

      for (const classToken of specialClass.split(' ')) {
        this.renderer.addClass(document.body, classToken);
      }
    } else if (this.currentSpecialClass) {
      for (const classToken of this.currentSpecialClass.split(' ')) {
        this.renderer.removeClass(document.body, classToken);
      }

      this.currentSpecialClass = null;
    }
  }

  private setupMutationObserver() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          document.querySelectorAll('[aria-owns]').forEach((element) => {
            if (element instanceof Element) {
              element.removeAttribute('aria-owns');
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Initial cleanup for any elements already in the DOM
    document.querySelectorAll('[aria-owns]').forEach((element) => {
      if (element instanceof Element) {
        element.removeAttribute('aria-owns');
      }
    });
  }
}
