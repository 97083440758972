import { z } from 'zod';
import { LoginTypes } from '@transect-nx/models';

export const GetLoginTypeResponse = z.object({
  loginType: z.nativeEnum(LoginTypes),
});

export type GetLoginTypeResponse = z.infer<typeof GetLoginTypeResponse>;

export const GetLoginTypeQueryParams = z.object({
  email: z
    .string()
    .email()
    .trim()
    .transform((v) => v.toLowerCase()),
});

export type GetLoginTypeQueryParams = z.infer<typeof GetLoginTypeQueryParams>;

export const PostSignUpBodyParams = z.object({
  email: z
    .string()
    .email()
    .trim()
    .transform((v) => v.toLowerCase()),
  firstName: z.string(),
  lastName: z.string(),
  industryId: z.string().uuid(),
  phone: z.string().nullable(),
});

export type PostSignUpBodyParams = z.infer<typeof PostSignUpBodyParams>;
