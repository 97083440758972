import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import {
  CatalogItemRegion,
  CatalogItemStatusExpert,
} from '@transect-nx/models';

export const UpdateCatalogItemBodyDTO = z
  .object({
    active: z.preprocess((value) => Boolean(value), z.boolean()),
    admin: z.object({}).passthrough().nullable(),
    catalog_group__id: z.string().uuid(),
    category: z.enum(['environmental', 'species', 'waters']).nullable(),
    center_point: z.object({
      latitude: z.number().nullable(),
      longitude: z.number().nullable(),
      zoomLevel: z.number().nullable(),
    }),
    description: z.string().nullable(),
    context: z.object({}).passthrough().nullable(),
    customer__id: z.string().uuid().nullable(),
    geography__id: z.string().uuid().nullable(),
    name: z.string(),
    owner__id: z.string().uuid().nullable(),
    public: z.preprocess((value) => Boolean(value), z.boolean()),
    region: z.nativeEnum(CatalogItemRegion).nullable(),
    review_by: DateSchema.nullable(),
    new_until_date: DateSchema.nullable(),
    schema: z.object({}).passthrough().nullable(),
    style_definition: z.object({}).passthrough().nullable(),
    status_expert: z.nativeEnum(CatalogItemStatusExpert).nullable(),
    geometry: GeometrySchema.nullable(),
    include_in_buildable_area: z.boolean().nullish(),
  })
  .deepPartial();

export type UpdateCatalogItemBodyDTO = z.infer<typeof UpdateCatalogItemBodyDTO>;
