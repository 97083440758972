import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { forkJoin, from, lastValueFrom, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import Session from 'supertokens-web-js/recipe/session';
import {
  AuthService,
  SecondFactorClaim,
  SecondFactorRequiredClaim,
} from '../services/auth.service';
import { SessionClaimsService } from '../services/session-claims.service';
import { TransectPlanKey } from '@transect-nx/models';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionClaimsService: SessionClaimsService,
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const stateUrl =
      this.router.getCurrentNavigation()?.extractedUrl.toString() || '';
    return this.performCheck(stateUrl, route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.performCheck(state.url, route);
  }

  private performCheck(
    stateUrl: string,
    route: ActivatedRouteSnapshot | Route,
  ): Observable<boolean | UrlTree> {
    return forkJoin({
      user: this.authService.userOrNull$.pipe(take(1)),
      isTokenSet: from(this.authService.isTokenSet()).pipe(take(1)),
      doesSessionExist: Session.doesSessionExist(),
    }).pipe(
      switchMap(async ({ isTokenSet, user, doesSessionExist }) => {
        const payload = doesSessionExist
          ? await this.authService.getTokenPayload()
          : null;

        if (payload?.sign_up) {
          return this.router.createUrlTree(['/signup'], {
            queryParams: {
              fill_email: payload.email,
              type: payload.third_party_id,
            },
          });
        }

        if (isTokenSet && !user) {
          user = await lastValueFrom(
            this.authService.fetchCurrentUser().pipe(take(1)),
          );
        }

        let plan: TransectPlanKey | null = null;

        if (payload) {
          plan =
            this.sessionClaimsService.TransectPlanClaim.getValueFromPayload(
              payload,
            );
        }

        if (!user && route?.data?.allowPublicUser) {
          return true;
        }

        if (!user) {
          this.authService.redirectUrl = this.router.parseUrl(stateUrl);
          return this.router.createUrlTree(['/login']);
        }

        if (plan === TransectPlanKey.NoAccess && user.role !== 'public-user') {
          return this.router.createUrlTree(['/no-access'], {
            queryParams: {
              type: 'no-company-account',
            },
          });
        }

        if (!user.verified || user.role === 'unverified') {
          return this.router.createUrlTree(['/no-access'], {
            queryParams: {
              type: 'unverified',
            },
          });
        }

        if (user.role === 'public-user' && !route?.data?.allowPublicUser) {
          return this.router.createUrlTree(['/no-access'], {
            queryParams: {
              type: 'public-user',
            },
          });
        }

        const secondFactorCompleted = await Session.getClaimValue({
          claim: SecondFactorClaim,
        });

        const secondFactorRequired = await Session.getClaimValue({
          claim: SecondFactorRequiredClaim,
        });

        if (secondFactorRequired && !secondFactorCompleted) {
          this.authService.redirectUrl = this.router.parseUrl(stateUrl);

          return this.router.createUrlTree(['/login']);
        }

        return true;
      }),
    );
  }
}
